/* tinos-400normal - latin */
@font-face {
  font-family: 'Tinos';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Tinos Regular '),
    local('Tinos-Regular'),
    url('./files/tinos-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/tinos-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* tinos-400italic - latin */
@font-face {
  font-family: 'Tinos';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Tinos Regular italic'),
    local('Tinos-Regularitalic'),
    url('./files/tinos-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/tinos-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* tinos-700normal - latin */
@font-face {
  font-family: 'Tinos';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Tinos Bold '),
    local('Tinos-Bold'),
    url('./files/tinos-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/tinos-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* tinos-700italic - latin */
@font-face {
  font-family: 'Tinos';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Tinos Bold italic'),
    local('Tinos-Bolditalic'),
    url('./files/tinos-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/tinos-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

